import React from "react";
import { Navigate, Routes, Route } from 'react-router-dom';
import LoginPage from "./pages/Login/LoginPage";
import HomePage from "./pages/Home/HomePage";
import AmbassadorsPage from "./pages/Ambassadors/AmbassadorsPage";
import IncubatorsPage from "./pages/Incubators/IncubatorsPage";
import EvaluatorsPage from "./pages/Evaluators/EvaluatorsPage";
import BoostersPage from "./pages/Boosters/BoostersPage";
import TrainingsPage from "./pages/Trainings/TrainingsPage";
import ExpertiseAreasPage from "./pages/ExpertiseAreas/ExpertiseAreasPage";
import RriToolsPage from "./pages/RriTools/RriToolsPage";
import OldRriToolsPage from "./pages/RriToolsOLD/OldRriToolsPage";
import UsersPage from "./pages/Users/UsersPage";
import './App.css';
import RouteGuard from "./auth/RouteGuard";
import GuestGuard from "./auth/GuestGuard";
import ChangePasswordPage from "./pages/Users/ChangePasswordPage";
import MailListPage from "./pages/MailList/MailListPage";

function App() {
    return (
        <>
            <div id="splash" data-visible="true">
                <div className="lds-ripple"><div></div><div></div></div>
            </div>
            <div id="page">
                <Routes>
                    <Route element={<RouteGuard />}>
                        <Route element={<GuestGuard />}>
                            <Route path="/" element={<HomePage />} exact />
                            <Route path="/ambassadors" element={<AmbassadorsPage />} exact />
                            <Route path="/incubators" element={<IncubatorsPage />} exact />
                            <Route path="/evaluators" element={<EvaluatorsPage />} exact />
                            <Route path="/boosters" element={<BoostersPage />} exact />
                            <Route path="/trainings" element={<TrainingsPage />} exact />
                            <Route path="/expertise-areas" element={<ExpertiseAreasPage />} exact />
                            <Route path="/rri-tools" element={<RriToolsPage />} exact />
                        </Route>
                        <Route path="/old-rri-tools" element={<OldRriToolsPage />} exact />
                        <Route element={<GuestGuard />}>
                            <Route path="/mail-list" element={<MailListPage />} exact />
                            <Route path="/users" element={<UsersPage />} exact />
                            <Route path="/change-password" element={<ChangePasswordPage />} exact />
                        </Route>
                    </Route>
                    <Route path="/login" element={<LoginPage />} exact />
                    <Route path='*' element={<Navigate to='/' replace={true} />} />
                </Routes>
            </div>
        </>
    );
}

export default App;
