import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import { jwtDecode } from "jwt-decode";
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import User from './User';

function Navbar() {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const pathname = useLocation().pathname;

    const token = localStorage.getItem("token");
    if (!token) {
        return false;
    }
    let decodedToken = jwtDecode(token);
    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <div className='navbar'>
                <Link to="#" className='menu-bars'>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
                <User />
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items' onClick={showSidebar}>
                    <li className='navbar-toggle'>
                        <Link to='#' className='menu-bars'>
                            <AiIcons.AiOutlineClose />
                        </Link>
                    </li>
                    {
                        decodedToken.role !== 'Guest' && SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    {
                                        item.cName !== 'divider' && <Link to={item.path} className={`${pathname === item.path ? 'active' : 'link'}`}>
                                            {item.icon} <span>{item.title}</span>
                                        </Link>
                                    }
                                </li>
                            );
                        })
                    }
                    {
                        decodedToken.role === 'Guest' && <li className="nav-text">
                        {
                            <Link to="/old-rri-tools" className={`${pathname === '/old-rri-tools' ? 'active' : 'link'}`}>
                                <RiIcons.RiToolsFill /> <span>OLD Rri Tools</span>
                            </Link>
                        }
                    </li>
                    }
                    
                </ul>
            </nav>
        </IconContext.Provider>
    )
}

export default Navbar;