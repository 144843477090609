import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const GuestGuard = () => {

    function hasJwt() {
        const token = localStorage.getItem("token");
        if (!token) {
            return false;
        }
        let decodedToken = jwtDecode(token);

        // JWT exp is in seconds
        return decodedToken.role !== "Guest";
    }

    let auth = hasJwt();
    return (
        auth ? <Outlet /> : <Navigate to="/old-rri-tools" />
    );
};

export default GuestGuard;