import Axios from "axios";
import * as FaIcons from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect, useRef } from "react";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { TiExport } from "react-icons/ti";
import { MdEdit } from "react-icons/md";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Navbar from "../../components/Navbar";
import ReactPaginate from "react-paginate";
import './OldRriToolsPage.css';
import 'react-toastify/dist/ReactToastify.css';

export default function OldRriToolsPage() {
    const [updateRriTool, setUpdateRriTool] = useState("");
    const [rriTools, setRriTools] = useState([]);
    const [showUpdate, setShowUpdate] = useState(false);

    const formModelSchema = yup.object({
        title: yup.string().required("Field is required"),
        authors: yup.string().notRequired(),
        summary: yup.string().required("Field is required"),
        yearOfPublication: yup.string().notRequired(),
        url: yup.string().notRequired(),
        resourceTypes: yup.string().required("Field is required"),
        keywords: yup.array().required("Field is required").min(1, "Field is required"),
        resourcePotentialUsers: yup.array().required("Field is required").min(1, "Field is required"),
    });

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(formModelSchema)
    });
    const [keywords, setKeywords] = useState([]);
    const [resourceTypes, setResourceTypes] = useState([]);
    const [resourcePotentialUsers, setResourcePotentialUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const [metadata, setMetadata] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const searchTextRef = useRef("");

    const handleCloseUpdateModal = () => {
        setShowUpdate(false);
        reset();
    }
    const handleShowUpdateModal = () => setShowUpdate(true);

    const handleClickUpdateModal = async (id) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/old-rri-tools/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        let rriToolUpdate = data;
        rriToolUpdate.resourceTypes = data.resourceTypes ? data.resourceTypes[0] : "1b4db7eb-4057-5ddf-91e0-36dec72071f5";
        rriToolUpdate.keywords = data.keywords ? data.keywords : [];
        rriToolUpdate.resourcePotentialUsers = data.resourceTypePotentialUsers ? data.resourceTypePotentialUsers : [];
        setUpdateRriTool(rriToolUpdate);
        await getKeywords();
        await getResourceTypes();
        await getResourcePotentialUsers();
        setValue('title', rriToolUpdate.title);
        setValue('authors', rriToolUpdate.authors);
        setValue('summary', rriToolUpdate.content);
        setValue('yearOfPublication', rriToolUpdate.publicationDate);
        setValue('url', rriToolUpdate.url);
        setValue('resourceTypes', rriToolUpdate.resourceTypes);
        setValue('keywords', rriToolUpdate.keywords);
        setValue('resourcePotentialUsers', rriToolUpdate.resourcePotentialUsers);
        handleShowUpdateModal();
    }

    const handleClickExportModal = async (id) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.post(`${apiUrl}/api/old-rri-tools/${id}/export`, {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(async () => {
            toast.success('Rri tool successfully exported', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchRriTools();
            handleCloseUpdateModal();
            setUpdateRriTool("");
        }).catch(function (error) {
            if (error.response.data.type === "RriTool.DataMissed") {
                toast.error("First update the RRI Tool", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        });
    }

    const getKeywords = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/rri-tools/keywords`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setKeywords(data);
    }

    const getResourceTypes = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/rri-tools/resource-types`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setResourceTypes(data);
    }

    const search = async (e) => {
        e.preventDefault();
        await fetchRriTools(1);
    }

    const getResourcePotentialUsers = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/rri-tools/resource-potential-users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setResourcePotentialUsers(data);
    }

    const clear = async () => {
        searchTextRef.current.value = "";
        await fetchRriTools(1);
    }

    const updateOnSubmit = data => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const newData = {
            title: data.title,
            authors: data.authors,
            summary: data.summary,
            yearOfPublication: data.yearOfPublication,
            url: data.url,
            resourceTypes: data.resourceTypes,
            keywords: data.keywords.join(','),
            resourcePotentialUsers: data.resourcePotentialUsers.join(',')
        };
        Axios.put(`${apiUrl}/api/old-rri-tools/${updateRriTool.id}`,
            newData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(async () => {
            toast.success('Rri tool successfully updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchRriTools();
            handleCloseUpdateModal();
            setUpdateRriTool("");
        }).catch(function (error) {
            toast.error(error.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        });
    }

    const fetchRriTools = async (selected) => {
        let page = currentPage;
        if (selected) {
            setCurrentPage(selected);
            page = selected;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = `${apiUrl}/api/old-rri-tools?page=${page}&size=${postsPerPage}`;
        if (searchTextRef.current.value !== "") {
            url = url + `&search=${searchTextRef.current.value}`;
        }
        const token = localStorage.getItem("token");
        const { data, headers } = await Axios.get(
            url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const pagination = JSON.parse(headers.get('x-pagination'));
        setMetadata(pagination);
        setTotalPages(pagination.TotalPages);

        const rriTools = data;
        setRriTools(rriTools);
    };

    useEffect(() => {
        fetchRriTools();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Modal size="xl" show={showUpdate} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Rri tool</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit(updateOnSubmit)} id="updateForm">
                        <Form.Group className="mb-4" controlId="updateRriTool.title">
                            <Form.Label>Title</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                {...register("title")}
                                placeholder="title"
                            />
                            {errors.title && <div className="d-grid gap-2 mt-3 error">{errors.title.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.authors">
                            <Form.Label>Name of the authors</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.authors ? 'is-invalid' : ''}`}
                                {...register("authors")}
                                placeholder="authors"
                            />
                            {errors.authors && <div className="d-grid gap-2 mt-3 error">{errors.authors.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.summary">
                            <Form.Label>Summary</Form.Label>
                            <textarea
                                className={`form-control ${errors.summary ? 'is-invalid' : ''}`}
                                placeholder="summary"
                                {...register("summary")}
                            />
                            {errors.summary && <div className="d-grid gap-2 mt-3 error">{errors.summary.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.yearOfPublication">
                            <Form.Label>Year of publication</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.yearOfPublication ? 'is-invalid' : ''}`}
                                {...register("yearOfPublication")}
                                placeholder="Year of publication"
                            />
                            {errors.yearOfPublication && <div className="d-grid gap-2 mt-3 error">{errors.yearOfPublication.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.url">
                            <Form.Label>Link (Url)</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                                placeholder="Url"
                                {...register("url")}
                            />
                            {errors.url && <div className="d-grid gap-2 mt-3 error">{errors.url.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.resourceTypes">
                            <Form.Label>Type of resource</Form.Label>
                            <Form.Select defaultValue={updateRriTool.resourceTypes} onChange={(val) => { updateRriTool.resourceTypes = val.currentTarget.value; setValue('resourceTypes', val.currentTarget.value) }}>
                                {
                                    resourceTypes.map((item, _) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        );
                                    })
                                }
                            </Form.Select>
                            {errors.resourceTypes && <div className="d-grid gap-2 mt-3 error">{errors.resourceTypes.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.keywords">
                            <Form.Label>Keywords</Form.Label>
                            {
                                keywords.map((item, index) => {
                                    return (
                                        <Form.Check type={"checkbox"} key={item.id} name="keywords" id={`keywords-${index}`}>
                                            <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={updateRriTool?.keywords?.includes?.(item.id)}
                                                onClick={(e) => {
                                                    if (e.target.checked) {
                                                        updateRriTool.keywords = [...updateRriTool.keywords, item.id];
                                                        setValue('keywords', [...updateRriTool.keywords, item.id]);
                                                    } else {
                                                        updateRriTool.keywords = updateRriTool.keywords.filter((id) => id !== item.id);
                                                        setValue('keywords', updateRriTool.keywords.filter((id) => id !== item.id));
                                                    }
                                                }}
                                            />
                                            <Form.Check.Label>{item.name}</Form.Check.Label>
                                        </Form.Check>
                                    );
                                })
                            }
                            {errors.keywords && <div className="d-grid gap-2 mt-3 error">{errors.keywords.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="updateRriTool.resourcePotentialUsers">
                            <Form.Label>Potential users</Form.Label>
                            {
                                resourcePotentialUsers.map((item, index) => {
                                    return (
                                        <Form.Check type={"checkbox"} key={item.id} name="resourcePotentialUsers" id={`resourcePotentialUsers-${index}`}>
                                            <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={updateRriTool?.resourcePotentialUsers?.includes(item.id)}
                                                onClick={(e) => {
                                                    if (e.target.checked) {
                                                        updateRriTool.resourcePotentialUsers = [...updateRriTool.resourcePotentialUsers, item.id];
                                                        setValue('resourcePotentialUsers', [...updateRriTool.resourcePotentialUsers, item.id]);
                                                    } else {
                                                        updateRriTool.resourcePotentialUsers = updateRriTool.resourcePotentialUsers.filter((id) => id !== item.id);
                                                        setValue('resourcePotentialUsers', updateRriTool.resourcePotentialUsers.filter((id) => id !== item.id));
                                                    }
                                                }}
                                            />
                                            <Form.Check.Label>{item.name}</Form.Check.Label>
                                        </Form.Check>
                                    );
                                })
                            }
                            {errors.resourcePotentialUsers && <div className="d-grid gap-2 mt-3 error">{errors.resourcePotentialUsers.message}</div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" form="updateForm">
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="content d-flex flex-column flex-column-fluid">
                <div className='container-fluid'>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-body">
                                    <div className="d-flex flex-row align-items-center w-100 float-start">
                                        <div className="input-group">
                                            <input placeholder="Search text" className="form-control form-control-sm" ref={searchTextRef}></input>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center w-100 float-start">
                                        <div className="mt-2">
                                            <Button variant="primary" className="btn btn-primary btn-sm" onClick={(e) => search(e)}>Search</Button>
                                            <Button variant="secondary" className="btn btn-secondary btn-sm ms-2"
                                                onClick={() => { clear() }}>Clear</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-5 g-lg-10 mt-2'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">
                                            Old Rri Tools
                                        </h1>
                                    </div>
                                    <div className="d-flex flex-row align-items-center w-50 float-end">
                                        <div className="input-group me-5 float-end align-right justify-content-end">
                                            <Button variant="secondary" className="btn-icon btn-md w-35px h-35px">
                                                <FaIcons.FaFileCsv />
                                            </Button>
                                        </div>
                                        <div className="input-group ms-5">
                                            <label htmlFor="perpageList" className="col-form-label col-form-label-sm">Show per page: </label>
                                            <select name="perpageList" className="form-select form-select-sm ms-2" onChange={(e) => setPostsPerPage(e.target.value)} defaultValue={postsPerPage}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="table-responsive">
                                                    <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                        <thead className="fw-bold text-muted">
                                                            <tr>
                                                                <th>Image</th>
                                                                <th className="sorting">Title</th>
                                                                <th className="sorting">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                rriTools.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                            <td className="w-50px">
                                                                                <div className="card-image">
                                                                                    {item.image && (
                                                                                        <img src={`data:image/png;base64,${item.image}`} alt={`tool${index}`} />
                                                                                    )}
                                                                                    {!item.image && (
                                                                                        <img src="/descarga.jpg" alt={`tool${index}`} />
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="min-w-150px">
                                                                                <div className="mb-2">{item.title}</div>
                                                                            </td>
                                                                            <td className="align-items-center">
                                                                                <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-2" onClick={() => handleClickUpdateModal(item.id)}>
                                                                                    <MdEdit />
                                                                                </Button>
                                                                                <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-2" onClick={() => handleClickExportModal(item.id)}>
                                                                                    <TiExport />
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <div className="dataTables_info">Showing {rriTools.length} of {metadata.TotalCount} entries</div>
                                        </div>
                                    </div>
                                    {
                                        totalPages > 1 && <div className="row mt-2">
                                            <div className="col">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    pageCount={totalPages}
                                                    onPageChange={({ selected }) => { fetchRriTools(selected + 1) }}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"page-link"}
                                                    nextLinkClassName={"page-link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"active"}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    currentPage={currentPage - 1}>
                                                </ReactPaginate>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}