import { React, useState } from "react";
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import './LoginPage.css';
import logo from '../../logo.png';
import 'bootstrap/dist/css/bootstrap.css';
import { useForm } from "react-hook-form";

export default function LoginPage() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const [error, setError] = useState('');

    const setAuthToken = token => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        else {
            delete axios.defaults.headers.common["Authorization"];
        }
    }

    const onSubmit = async data => {
        const loginPayload = {
            email: data.email,
            password: data.password
        };
        const apiUrl = process.env.REACT_APP_API_URL;
        axios.post(`${apiUrl}/api/users/login`, loginPayload).then(response => {
            //get token from response
            const token = response.data;
            //set JWT token to local
            localStorage.setItem("token", token);
            let decodedToken = jwtDecode(token);
            if (decodedToken.role === 'Guest') {
                window.location.href = '/old-rri-tools';
            } else {
                //redirect user to home page
                window.location.href = '/';
            }
            //set token to axios common header
            setAuthToken(token);
            setError('');
        }).catch(err => setError(err.response.data.detail));
    }
    return (
        <div className="auth-form-container">
            <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-form-content">
                    <div id="logo">
                        <a href="/"><img src={logo} alt="reinforcing" /></a>
                    </div>
                    <h2 className="auth-form-title">Sign In</h2>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="email"
                            className="form-control mt-2"
                            placeholder="Enter email"
                            {...register("email", {
                                required: "Required",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered value does not match email format",
                                }
                            })}
                        />
                        {errors.email && <div className="d-grid gap-2 mt-3 error">{errors.email.message}</div>}
                    </div>
                    <div className="form-group mt-5">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-2"
                            placeholder="Enter password"
                            {...register("password", {
                                required: "Required",
                                pattern: {
                                    value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                    message: "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
                                }
                            })}
                        />
                        {errors.password && <div className="d-grid gap-2 mt-3 error">{errors.password.message}</div>}
                    </div>
                    <div className="d-grid gap-2 mt-5">
                        <button type="submit" className="btn submit">
                            Submit
                        </button>
                    </div>
                    {error !== '' &&
                        <div className="d-grid gap-2 mt-3 error">
                            {error}
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}