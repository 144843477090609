import React from "react";

import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as LiIcons from "react-icons/lia";
import * as FaIcons from "react-icons/fa";

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'Ambassadors',
        path: '/ambassadors',
        icon: <RiIcons.RiUserStarFill />,
        cName: 'nav-text'
    },
    {
        title: 'Incubators',
        path: '/incubators',
        icon: <RiIcons.RiGroupFill />,
        cName: 'nav-text'
    },
    {
        title: 'Evaluators',
        path: '/evaluators',
        icon: <RiIcons.RiUser3Fill />,
        cName: 'nav-text'
    },
    {
        title: 'Boosters',
        path: '/boosters',
        icon: <RiIcons.RiUser5Fill />,
        cName: 'nav-text'
    },
    {
        title: 'Areas of expertise',
        path: '/expertise-areas',
        icon: <LiIcons.LiaShareAltSolid />,
        cName: 'nav-text'
    },
    {
        title: 'Rri Tools',
        path: '/rri-tools',
        icon: <RiIcons.RiToolsFill />,
        cName: 'nav-text'
    },
    {
        title: 'OLD Rri Tools',
        path: '/old-rri-tools',
        icon: <RiIcons.RiToolsFill />,
        cName: 'nav-text'
    },
    {
        title: 'Trainings',
        path: '/trainings',
        icon: <FaIcons.FaChalkboardTeacher />,
        cName: 'nav-text'
    },
    {
        title: 'Mail list',
        path: '/mail-list',
        icon: <AiIcons.AiOutlineMail />,
        cName: 'nav-text'
    },
    {
        title: '',
        path: '',
        icon: '',
        cName: 'divider'
    },
    {
        title: 'Users',
        path: '/users',
        icon: <BiIcons.BiSolidUser />,
        cName: 'nav-text'
    },
    {
        title: 'Change password',
        path: '/change-password',
        icon: <RiIcons.RiLockPasswordFill />,
        cName: 'nav-text'
    }
]